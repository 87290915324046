<style>
	.svelte-tabs__tab-list {
    border-bottom: 1px solid #CCCCCC;
    margin: 0;
    padding: 0;
	}
</style>

<ul role="tablist" class="svelte-tabs__tab-list">
  <slot></slot>
</ul>
